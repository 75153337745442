import React, { useContext } from 'react'
import { Layout, SEO } from 'components/common'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'components/vertical-timeline'
import Briefcase from 'assets/svgs/briefcase.svg'
import Hat from 'assets/svgs/graduation.svg'
import styled from 'styled-components'
import { ThemeContext } from 'providers/ThemeProvider'

export default () => {
  const {theme} = useContext(ThemeContext)
  return (
    <Layout>
      <SEO title="Resume" type="Organization" location="/resume" />
      <Wrapper theme={theme}>
      <VerticalTimeline layout="2-columns">
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="March 2022 - Present"
          icon={<Briefcase />}
        >
          <h3 className="vertical-timeline-element-title">Fullstack Engineer @Toptal</h3>
          <h4 className="vertical-timeline-element-subtitle">Remote</h4>
          <p>
            ReactJS, NextJS, React Native, NodeJS
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="May 2022 - December 2023"
          icon={<Briefcase />}
        >
          <h3 className="vertical-timeline-element-title">Senior Frontend Developer @Air.inc</h3>
          <h4 className="vertical-timeline-element-subtitle">Remote (Contractor)</h4>
          <p>
            ReactJS, NextJS, React Query, Contentful 
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Jan 2021 - March 2022"
          icon={<Briefcase />}
        >
          <h3 className="vertical-timeline-element-title">Fullstack Engineer @Slexn</h3>
          <h4 className="vertical-timeline-element-subtitle">Seoul, South Korea</h4>
          <p>
            React Native, ReactJS, Android Java, Docker, CI/CD, NodeJS
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Apr 2020 - Jan 2021"
          icon={<Briefcase />}
        >
          <h3 className="vertical-timeline-element-title">Mobile Developer @Damogo</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Seoul, South Korea
          </h4>
          <p>
            React Native, GraphQL, TypeScript, Fastlane
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Sep 2019 - Mar 2020"
          icon={<Briefcase />}
        >
          <h3 className="vertical-timeline-element-title">Frontend Developer @SovereignWallet</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Seoul, South Korea
          </h4>
          <p>ReactJS, React Native, Redux, Web3</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Jan 2019 - Aug 2019"
          icon={<Briefcase />}
          >
          <h3 className="vertical-timeline-element-title">Frontend Developer @Mosanada</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Remote
          </h4>
          <p>ReactJS, GraphQL, Ant Design, TypeScript</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="Sep 2016 - Dec 2020"
          icon={<Hat />}
          >
          <h3 className="vertical-timeline-element-title">
          Bachelor in Computer Science
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Sejong University</h4>
          <p>C, C++, Python, Java, Algorithms</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="Sep 2013 - Jun 2016"
          icon={<Hat />}
        >
          <h3 className="vertical-timeline-element-title">
            Exact Sciences 
          </h3>
          <h4 className="vertical-timeline-element-subtitle">NamSU Academic Lyceum 2</h4>
          <p>Algebra, Geometry, Calculus, ESL</p>
        </VerticalTimelineElement>
      </VerticalTimeline>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
    .briefcase,
    .grad-icon{
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      user-select: none;
      flex-shrink: 0;
      transform: scale(1.2);
    }
    .grad-icon{
      fill: #d23639;
    }
    .vertical-timeline-element-content{
      background:  #00c3ff; 
      box-shadow: 0 3px 0 #00c3ff;
      color: #fff;
    }
    .vertical-timeline-element-icon{
      background: #fff;
      box-shadow: 0 0 0 4px #00c3ff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
    }
    .vertical-timeline.vertical-timeline--two-columns:before{
      background: #00c3ff;
    }
    .vertical-timeline-element-content-arrow{
      border-right: 7px solid #00c3ff;
    }
    .vertical-timeline-element--work .vertical-timeline-element-date{
      color: ${({theme}) => theme === 'dark' ? '#fff' : '#000'};
    }
    .vertical-timeline-element--education .vertical-timeline-element-date{
      color: ${({theme}) => theme === 'dark' ? '#fff' : '#d23639'};
    }
    .vertical-timeline-element-subtitle{
      margin-top: .7rem;
    }
`